import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Category table`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sub-categories`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BROADCAST INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#broadcast-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CAMPAIGN INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#campaign-info->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CAMPAIGN INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#campaign-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CART CREATION DATE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#cart-creation-date->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CONTACT INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-info->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CONTACT INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-classification">{`Classification`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-contact-finalization">{`Contact finalization`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-dates-and-times">{`Dates and times`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-general">{`General`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-location-information">{`Location information`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-operator">{`Operator`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-other-services">{`Other services`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#contact-information->-specific-by-contact-type">{`Specific by contact type`}</a></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CONVERSION EVENTS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#conversion-events->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CUSTOMER INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#customer-information->-customer-identification">{`Customer identification`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#customer-information->-segments">{`Segments`}</a></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DEVICE INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#device-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ECOMMERCE INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#ecommerce-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EVENTS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#events->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GENERAL NPS INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#general-nps-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LEAD INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#lead-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LOCATION INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#location-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OUTBOUND CAMPAIGN CONTACT INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#outbound-campaign-contact-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OUTBOUND CAMPAIGNS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#outbound-campaigns->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SEND INFO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#send-info->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SESSION INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#session-information->-device-information">{`Device information`}</a></li><li><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#session-information->-general">{`General`}</a></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VISITORS INFORMATION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a href="/engagement-cloud/analytics/analytics-panel-reference/fields-by-sub-category/#visitors-information->->-without-sub-category"><b>{`Without sub-category`}</b></a></p></td>
        </tr>
      </tbody>
    </table>
    <h2>{`BROADCAST INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
              }}>{`Broadcast Contacts`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Access time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time between the time of the contact request to access the broadcast and the time that the access is granted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounce time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount of time that the visitor spends on the broadcast.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Broadcast ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the broadcast. For example, `}<inlineCode parentName="td">{`12345-Father's day promotion`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Like`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact liked the broadcast.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't like the broadcast.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Messages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor comments to the streamed video. For example, `}<inlineCode parentName="td">{`I both the product and I am extremelly satisfied witbh it. It's a must have.`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPS Mood`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood of the visitor that CoreMedia Experience Platform detected in the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPS Score`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Net Promoter Score (NPS) value, typically, is a value between 0 and 10, where 0 means that the customer is not happy and will not recommend the business to anyone, and 10 means that the customer is very happy and will recommend the business to everyone.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rating that the customer gives to the streamed video. Values between `}<inlineCode parentName="td">{`1`}</inlineCode>{` and `}<inlineCode parentName="td">{`5`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Streaming date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of the Streaming in format YYYY-MM-DD hh:mm:ss. For example, `}<inlineCode parentName="td">{`2025-03-19 12:00:03`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Streaming name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the streaming.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of visits during the broadcast.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CAMPAIGN INFO > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-email/"
                }}>{`Outbound Campaigns Email`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push-actions/"
                }}>{`Outbound Campaigns Push Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push/"
                }}>{`Outbound Campaigns Push`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-sms/"
                }}>{`Outbound Campaigns SMS`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-voice/"
                }}>{`Outbound Campaigns Voice`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`From`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email that appears in the sent email. For example, `}<inlineCode parentName="td">{`do-no-reply@amazing-campaign.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`outbound campaigns`}</strong>{`, name of the outbound voice campaign where Engagement Cloud created the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Motivation of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reply to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email address to where the receiver can reply.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Team of the operator that handled the interaction.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CAMPAIGN INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/events/"
                }}>{`Events`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/"
                }}>{`Outbound Campaigns Actions`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`onsite campaigns`}</strong>{`, name of the page element that contains the window from which the customer that visited the site abandoned the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the campaign action happened in format YYYY-MM-DD. For example, `}<inlineCode parentName="td">{`2025-03-19`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of action. One of the following:`}<ul><li>{`Email views.`}</li><li>{`Email link click.`}</li><li>{`Browser views.`}</li><li>{`Unsubscribed.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week day  when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Active element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`onsite campaigns`}</strong>{`, name of the active element that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of tags associated with the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External Campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, identifier of external campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, name of external campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, type of external campaign from which the customer requested the contact. For example, a Facebook campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of the names of the external campaigns that handled the customer until the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of the identifiers of the external campaigns that handled the customer until the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Link`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visited link.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the onsite campaign that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the onsite campaign that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`outbound campaigns`}</strong>{`, name of the outbound voice campaign where Engagement Cloud created the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the placeholder that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the component of the onsite campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of tags associated with the component of the onsite campaign from which the customer requested the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CART CREATION DATE > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/"
              }}>{`ECommerce`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the cart with format YYYY-MM-DD. For example, `}<inlineCode parentName="td">{`2025-03-11`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week of visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the weekday of the visit. One of the following:`}<ul><li>{`Sunday`}</li><li>{`Monday`}</li><li>{`Tuesday`}</li><li>{`Wednesday`}</li><li>{`Thursday`}</li><li>{`Friday`}</li><li>{`Saturday`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of the visit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of the campaign.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFO > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-email/"
                }}>{`Outbound Campaigns Email`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push-actions/"
                }}>{`Outbound Campaigns Push Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push/"
                }}>{`Outbound Campaigns Push`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-sms/"
                }}>{`Outbound Campaigns SMS`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-voice/"
                }}>{`Outbound Campaigns Voice`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact list that the campaign used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operating system of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Android`}</inlineCode>{`, `}<inlineCode parentName="td">{`Windows`}</inlineCode>{`, or  `}<inlineCode parentName="td">{`IPhone Osx`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email of the contact. For example, `}<inlineCode parentName="td">{`regular.joe@mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain of the contact email. For example, `}<inlineCode parentName="td">{`mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID in other campaigns.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsubscribe date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the contact unsubscribed the campaign.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/"
                }}>{`ECommerce`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/events/"
                }}>{`Events`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/ivr/"
                }}>{`IVR`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/"
                }}>{`Visitors`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempt number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts to reach the customer in a contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total duration of the contact represented as an interval. For example, `}<inlineCode parentName="td">{`16-30`}</inlineCode>{` minutes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer's evaluation of the operator who handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DTMF Success Rate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The percentage of DTMF signals that the IVR successfully detects and processes. For example, `}<inlineCode parentName="td">{`100%`}</inlineCode>{` or `}<inlineCode parentName="td">{`72%`}</inlineCode>{`.`}<br />{`A `}<a parentName="td" {...{
              "href": "/glossary/#dtmf-success-rate"
            }}>{`DTMF Success Rate`}</a>{` of 72% means that in every 100 times that the customer pressed a number to navigate a menu, 72 were corrected pressed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration in represented in an interval. For example, `}<inlineCode parentName="td">{`31-45`}</inlineCode>{` minutes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information retrieved from the contact form.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwarded to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number to where the IVR forwarded the interaction.`}<br /><br />{`If the `}<em parentName="td">{`IVR status`}</em>{` is `}<inlineCode parentName="td">{`Forwarded to sklill`}</inlineCode>{`, `}<em parentName="td">{`Forwarded to`}</em>{` contains the id of the skill to which the interaction was forwarded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has IVR?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes: An IVR handled the customer during some period of the interaction.`}</li><li>{`No: The interaction with the customer was never routed to the IVR.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`123456`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the IVR that handled the interaction. For example, `}<inlineCode parentName="td">{`Support`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration (s)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration of the interaction, in seconds, while in the IVR. If the IVR forwards the interaction to an operator, this duration doesn't include time spent with the operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the IVR interaction ended. For example, `}<inlineCode parentName="td">{`2025-01-09 14:42:06`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the IVR interaction started. For example, `}<inlineCode parentName="td">{`2025-01-09 14:41:36`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`None: Engagement Cloud did not classified the interaction. `}</li><li><strong parentName="td">{`Disconnected`}</strong>{`: The customer disconnected the interaction while in the IVS.`}</li><li><strong parentName="td">{`Finished`}</strong>{`: The customer or the IVR finished the interaction.`}</li><li><strong parentName="td">{`Forwarded to skill`}</strong>{`: The IVR forwarded the interaction to an operator.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the last node that handled the interaction. For example, node `}<inlineCode parentName="td">{`18`}</inlineCode>{` or `}<inlineCode parentName="td">{`forward_to_operator`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source or link that referred the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifiers of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`A1B345C6DEFGH789I0JK`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`January Promo`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL of the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL that contains the link to the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total of processed nodes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of nodes that handled the interaction. For example, `}<inlineCode parentName="td">{`14`}</inlineCode>{`.`}<br />{`If you click on a value on this column, the contact window opens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Trigger point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instant in the interaction that triggers the IVR. For example, `}<inlineCode parentName="td">{`Beginning of the call`}</inlineCode>{` or `}<inlineCode parentName="td">{`Operator transfer`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address that the customer visited to reach the contact center.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor record`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Record of the visitor containing name and email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Recognition Accuracy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/glossary/voice-recognition-accuracy"
            }}>{`Rate of successful voice recognition`}</a>{`. For example, in a voice command, an accuracy of `}<inlineCode parentName="td">{`100%`}</inlineCode>{` means that the IVR could understand all words that the customer said. An accuracy of `}<inlineCode parentName="td">{`50%`}</inlineCode>{` means that out of 4 words the IVR could only understand and process 2 words.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voicemail detection result`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Human`}</strong>{`: The detected voice was human.`}</li><li><strong parentName="td">{`Machine`}</strong>{`: The detected voice was from an answering machine or voicemail.`}</li><li><strong parentName="td">{`Not Sure`}</strong>{`: CoreMedia Experience Platform could not classify the answer.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center) (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the con`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while in queue. For example, while waiting for the operator after IVR.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while in queue represented in intervals.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited after requested to be contacted represented in intervals.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > Classification`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`# Bundles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/glossary/#bundles"
            }}>{`Packages that include multiple RGUs`}</a>{`. Number of packages sold during the `}<a parentName="td" {...{
              "href": "/glossary/#contact"
            }}>{`contact`}</a>{` with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`# RGUS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/glossary/#rgus"
            }}>{`Revenue Generating Unit`}</a>{`. Number of products or services sold during the `}<a parentName="td" {...{
              "href": "/glossary/#contact"
            }}>{`contact`}</a>{` with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the contact was classified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Sale?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes: The contact ended with a sale.`}</li><li>{`No: The contact ended without sales.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Value of the sale. It can be expressed in monetary units like € or $ or in RGUs.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > Contact finalization`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classification status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the classification of the contact after finalization. One of the following:`}<ul><li>{`Classified by Operator`}</li><li>{`Not Classified`}</li><li>{`Not Classified - Transferred`}</li><li>{`Not Classified - Expired Time`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of credits attributed to the operator that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Disconnection origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For chat interactions, entity responsible for ending the communication. One of the following:`}<ul><li>{`Operator`}</li><li>{`Customer`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cause of hangup for chat interactions with the customer. One of the following:`}<ul><li>{`Visitor disconnection`}</li><li>{`Ended by operator`}</li><li>{`Visitor inactivity`}</li><li>{`Ended by visitor`}</li></ul>{`Note that each client can add more hangup causes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause (contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cause of hangup for voice interactions with the contact center. One of the following:`}<ul><li>{`Completed transfer`}</li><li>{`Failed transfer`}</li><li>{`Canceled transfer`}</li><li>{`Busy`}</li></ul>{`Note that each client can add more hangup causes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has conversion?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes - If the contact ends with a conversion.`}</li><li>{`No - If the contact doesn't end with a conversion.`}</li></ul>{`Note that the definition of conversion depends on the campaign purpose. For example, the customer bought a product or the customer filled in a survey giving information to the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is it classified?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes - Engagement Cloud automatically classified the contact or the operator manually classified the contact.`}</li><li>{`No - Engagement Cloud failed to classify the contact or there is no classification available.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wrapup code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Code that the client defined to wrapup the contact. For example, `}<inlineCode parentName="td">{`Sale`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > Dates and times`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the contact started. For example, `}<inlineCode parentName="td">{`2025-01-15`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration (in operation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`If the customer calls the contact, this represents the time when the operator or the IVR answers the call until the call ends.`}</li><li>{`If the contact center calls a customer, this represents the time from when the customer answers the call until it ends.`}</li><li>{`If the `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Chat`}</inlineCode>{`, this represents the time when the customer receives the first message until the chat ends due to one of the following reasons:`}<ul><li>{`The operator ended the chat.`}</li><li>{`The chat was inactive.`}</li><li>{`Lack of persistence.`}</li></ul></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total duration of the contact from the time the interaction reaches the contact center until it finishes. For voice calls, it also includes time spend in the IVR.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of initial scheduling`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the first scheduling to contact the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, date when the contact hung up and ended the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of contact (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of 1st attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For outbound contacts, date of the first attempt to contact the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For outbound contacts, date of the first attempt that the customer answered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time from the beginning of the contact until the customer reaches an operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while on hold or, while waiting for information from the operator, or while waiting to reach an operator after an IVR transfer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time while the contact is in queue.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time since the costumer requested the contact until the first attempt to contact the customer happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week when the contact started`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the weekday when the contact started. One of the following:`}<ul><li>{`1 for Monday`}</li><li>{`2 for Tuesday`}</li><li>{`3 for Wednesday`}</li><li>{`4 for Thursday`}</li><li>{`5 for Friday`}</li><li>{`6 for Saturday`}</li><li>{`7 for Sunday`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of contact (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month when the contact started. For example, `}<inlineCode parentName="td">{`January 2025`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of contact (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the contact started. For example, `}<inlineCode parentName="td">{`2025`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > General`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/leads/"
                }}>{`Leads`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Billing Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the contact resulted in a sale, shows the billing status of the customer. One of the following:`}<ul><li><strong parentName="td">{`Success`}</strong>{`: The customer already payed the product.`}</li><li><strong parentName="td">{`Un-success`}</strong>{`: The customer had not payed for the product.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Caller ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For inbound voice contacts, phone number of the calling customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel of the interaction with the customer. One of the following:`}<ul><li>{`Default`}</li><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact hangup origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Who ended the interaction. One of the following:`}<ul><li>{`Operator`}</li><li>{`Customer`}</li><li>{`System`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact initial skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill of the first operator that handle the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Preferred language for inter with the customer. For example, `}<inlineCode parentName="td">{`Portuguese`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL from where the visitor requested the conctact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Success`}</li><li>{`Un-success`}</li></ul>{`Note that the `}<em parentName="td">{`Status per contact type`}</em>{` field contains details about each contact status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Voice`}</inlineCode>{`, one of the following: `}<ul><li><strong parentName="td">{`Click2Call`}</strong>{`: Contact created from a call that the customer requests using Click2Call.`}</li><li><strong parentName="td">{`Outbound`}</strong>{`: Contact created from a call to a phone number of a customer in a contact list without the intervention of an operator. For example, to play a promotional recording.`}</li><li><strong parentName="td">{`Callcenter Outbound`}</strong>{`: Contact created from a call to a phone number of a customer in a contact list that the CoreMedia Contact Center delivers to an operator, possibly, after playing a recording. For example, a call that the CoreMedia Contact Center delivers to an operator to allow the customer to buy a product after hearing the promotional recording.`}</li><li><strong parentName="td">{`Inbound`}</strong>{`: Contact created from a call that the customer makes to an operator.`}</li><li><strong parentName="td">{`Calltracker`}</strong>{`: Contact created from an inbound call with `}<a parentName="td" {...{
                      "href": "/glossary/#calltracker"
                    }}>{`calltracker`}</a>{`.`}</li><li><strong parentName="td">{`API`}</strong>{`: An API endpoint created the contact.`}</li><li><strong parentName="td">{`Workflow`}</strong>{`: A workflow function created the contact.`}</li><li><strong parentName="td">{`Active Manual`}</strong>{`: Contact created from an outbound call that the operator made manually.`}</li><li><strong parentName="td">{`Active Schedule`}</strong>{`: Contact created from an outbound call previously scheduled.`}</li></ul></li><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Video Broadcast`}</inlineCode>{`, one of the following:`}<ul><li><strong parentName="td">{`Video broadcast`}</strong>{`: Outbound call that plays a video to the customer.`}</li><li><strong parentName="td">{`Video2Call`}</strong>{`: Voice call with video.`}</li></ul></li><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` =  `}<inlineCode parentName="td">{`Chat`}</inlineCode>{`, one of the following:`}<ul><li><strong parentName="td">{`Live Chat`}</strong>{`: Contact created from a chat conversation with a live operator.`}</li><li><strong parentName="td">{`Chatbot`}</strong>{`: Contact created from an interaction with a chatbot.`}</li></ul></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Voice`}</strong>{`: Contact where the customer talks with an operator through a voice call. The voice call can be inbound, outbound, or Click2Call.`}</li><li><strong parentName="td">{`Video Broadcast`}</strong>{`: Voice calls that include video. `}</li><li><strong parentName="td">{`Chat`}</strong>{`: Contacts where customers use messaging to communicate with the contact center.`}</li><li><strong parentName="td">{`Forms`}</strong>{`: Voice, video, or chat contacts that the customer started using a form.`}</li><li><strong parentName="td">{`External`}</strong>{`: Contacts where the operator talks with the customer outside the CoreMedia Contact Center. After finishing, the operator creates the contact manually in the CoreMedia Contact Center.`}</li></ul>{`Note that the `}<em parentName="td">{`Contact Subtype`}</em>{` field contains details about each contact type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia CID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact unique identifier according to the contact type. For example, a voice contact can have CID = `}<inlineCode parentName="td">{`154242`}</inlineCode>{` and a chat contact can also have CID = `}<inlineCode parentName="td">{`154242`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia Main ID (Ticket ID)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact unique identifier.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation reason`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reason why the contact was created. For example, `}<inlineCode parentName="td">{`Blind transfer`}</inlineCode>{` or `}<inlineCode parentName="td">{`External campaign`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination of the interaction. For example, `}<inlineCode parentName="td">{`Click2Call`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direction of the interaction. One of the following:`}<ul><li>{`None`}</li><li>{`Inbound`}</li><li>{`Outbound`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tags associated to the operator that handle the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Origin contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the contact that cause the creation of this contact. For example, the visitor ends an interaction with an operator and the operator transfers the interaction to another department. In this case, the first operator ends the contact and a new contact is created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Source`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source of the interaction. For example, `}<inlineCode parentName="td">{`Phone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status per contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{` If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Success`}</inlineCode>{`, one of the following:`}<ul><li><strong parentName="td">{`Normal`}</strong>{`:  Every contact from the customer that reaches an operator.`}</li><li><strong parentName="td">{`Finished on IVR`}</strong>{`: Contacts where the customer is able to solve the problem or complete the intended task using just the IVR. For example, a call to the electric company to communicate monthly consumptions.`}</li><li><strong parentName="td">{`Template sent`}</strong>{`: Contacts that end with a template sent to WhatsApp.`}</li></ul></li><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Un-Success`}</inlineCode>{`, one of the following:`}<ul><li>{`Contact Center did not answer`}</li><li>{`Customer did answer, but the Contact Center didn't answer`}</li><li>{`Abandonment in queue`}</li><li>{`Scheduled contact`}</li><li>{`Customer did not answer`}</li><li>{`Disconnected on IVR`}</li><li>{`Contact Center didn't answer after IVR success`}</li><li>{`Impossible to sent`}</li></ul></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sub status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact sub status. For example, `}<inlineCode parentName="td">{`Normal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Team of the operator that handled the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time spent in the IVR node.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, one of the following:`}<ul><li>{`Inbound`}</li><li>{`Outbound`}</li><li>{`API`}</li><li>{`Calltracker`}</li><li>{`Click2Call`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > Location information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/leads/"
                }}>{`Leads`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the customer. For example, `}<inlineCode parentName="td">{`Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Internet provider of the customer. For example, `}<inlineCode parentName="td">{`Vodafone Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the city of the customer. For example, `}<inlineCode parentName="td">{`Lisbon`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > Operator`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the device of the operator. For example, `}<inlineCode parentName="td">{`Apple MacBook`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > Other services`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AI Classification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Automatic classification of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Summary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Summary of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Bidireccional Video?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact used bidirectional video.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't use bidirectional video.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has Video?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact used video.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't use video.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has cobrowse?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact used cobrowsing.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't use  cobrowsing.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has recording?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The interaction of the contact was recorded.`}</li><li><strong parentName="td">{`No`}</strong>{`: The interaction of the contact was not recorded.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The interaction of the contact a transcript.`}</li><li><strong parentName="td">{`No`}</strong>{`: The interaction of the contact doesn't have a transcription.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Have on-demand recording?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact has on-demand recording associated.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact doesn't have an on-demand recording associated.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human verified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact classification was manually verified.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact classification was not verified manually.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the recording.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording On-demand`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the recording.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sentiment Analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Evaluation of the mood of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transcript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the transcript.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONTACT INFORMATION > Specific by contact type`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Chat`}</inlineCode>{`, name of the chatbot that handled the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes`}</li><li>{`No`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Center number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the contact center that the customer used. For example, `}<inlineCode parentName="td">{`351211212121`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has triggered a schedule?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`No`}</strong>{`: The contact did not triggered a call schedule.`}</li><li><strong parentName="td">{`Scheduled`}</strong>{`: The contact triggered a the scheduled of a call to the customer.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number that the customer used to call the contact center. For example, `}<inlineCode parentName="td">{`351221234567`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is short call?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`No`}</li><li>{`Yes`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the last interaction of the customer with the contact center.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last interaction sender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Email`}</inlineCode>{`, the sender of the last email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the IVR nodes that handled the interaction before it was ended or delivered to an operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<em parentName="td">{`Contact Subtype`}</em>{` is `}<inlineCode parentName="td">{`API`}</inlineCode>{` or `}<inlineCode parentName="td">{`Click2Call`}</inlineCode>{`, contains the number of call attempts that the contact center made until the customer answers or until it reaches the maximum number of attempts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions associated with the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator identification method`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`API (A)`}</li><li>{`DTMF (D)`}</li><li>{`Console (T)`}</li><li>{`System (S)`}</li><li>{`Transfer (F)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual phone number that the customer used to call the contact center. Typically, mobile phone numbers.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice actions sequence`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CONVERSION EVENTS > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/events/"
              }}>{`Events`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversion Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the conversion. One of the following:`}<ul><li><strong parentName="td">{`assisted`}</strong>{`: A conversion has type `}<inlineCode parentName="td">{`assisted`}</inlineCode>{` if the visitor needed the help of an operator to end the conversion. For example, the customer had to talk to an operator to purchase a mobile phone or to submit a form.`}</li><li><strong parentName="td">{`direct`}</strong>{`: A conversion has type `}<inlineCode parentName="td">{`direct`}</inlineCode>{` if the customer was able to finalize the conversion without further help from an operator.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If applicable, value of the conversion. For example, `}<inlineCode parentName="td">{`730€`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Days until conversion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of days necessary for the customer to do the conversion. For example, `}<inlineCode parentName="td">{`29`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sessions until conversion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of sessions that the customer opened before converting. For example, `}<inlineCode parentName="td">{`10`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`CUSTOMER INFORMATION > Customer identification`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the cookie of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia VID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor unique identifier.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of visitor identification. For example, `}<inlineCode parentName="td">{`2031253224`}</inlineCode>{`.`}<br />{`One of the following:`}<ul><li><strong parentName="td">{`FId`}</strong>{`: The Digital Experience Platform generated the visitor identifier.`}</li><li><strong parentName="td">{`Cookie ID`}</strong>{`: Digital Experience Platform read the visitor ID from a Cookies.`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`CUSTOMER INFORMATION > Segments`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifiers of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`A1B345C6DEFGH789I0JK`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`January Promo`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`DEVICE INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/"
                }}>{`ECommerce`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/events/"
                }}>{`Events`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/"
                }}>{`Outbound Campaigns Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/"
                }}>{`Visitors`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser that the using is using. For example, `}<inlineCode parentName="td">{`Chrome Android`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphical resolution of the browser in pixels. For example, `}<inlineCode parentName="td">{`1536x864`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brand family of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Galaxy A12`}</inlineCode>{`, `}<inlineCode parentName="td">{`IPhone`}</inlineCode>{`, or `}<inlineCode parentName="td">{`Macintosh`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`SM-A125f`}</inlineCode>{`, `}<inlineCode parentName="td">{`Iphone 11`}</inlineCode>{`, or `}<inlineCode parentName="td">{`MacBook Pro`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operating system of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Android`}</inlineCode>{`, `}<inlineCode parentName="td">{`Windows`}</inlineCode>{`, or  `}<inlineCode parentName="td">{`IPhone Osx`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of device. One of the following:`}<ul><li>{`Smartphone`}</li><li>{`Tablet`}</li><li>{`Desktop`}</li><li>{`Console`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vendor of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Samsung`}</inlineCode>{`, `}<inlineCode parentName="td">{`Apple`}</inlineCode>{`, or `}<inlineCode parentName="td">{`HP`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email client`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email client that the visitor used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of te following:`}<ul><li>{`No - The visitor didn't use a mobile device to make the visit.`}</li><li>{`Yes - The visitor used a mobile device to make the visit.`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`ECOMMERCE INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/"
              }}>{`ECommerce`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`App Context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Context in the application where the activity occurred.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cart value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total value of the cart. For example, `}<inlineCode parentName="td">{`€787`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The customer made checkout of the cart.`}</li><li><strong parentName="td">{`No`}</strong>{`: The customer didn't make checkout of the cart yet.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`For Leads, creation date of the lead.`}</li><li>{`For Ecommerce, creation date of the cart. `}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Current step number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the cart. Number of the step in the checkout workflow that the cart is in. For example, the user is adding the shipping address which corresponds to step `}<inlineCode parentName="td">{`3`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Items number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of items in the cart. For example, `}<inlineCode parentName="td">{`7`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last update`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of the last update of the cart in the format YYYY-MM-DD hh:mm:ss. For example, `}<inlineCode parentName="td">{`2025-03-11 17:51:33`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Max step number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size of the checkout workflow. Maximum number of steps in the checkout workflow. For exaample, `}<inlineCode parentName="td">{`4`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Shipping cost`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cost of the associated shipping. For example, `}<inlineCode parentName="td">{`€ 4.99`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Shopping cart ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the shopping cart. For example, `}<inlineCode parentName="td">{`123456789`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`EVENTS > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/events/"
              }}>{`Events`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphical resolution of the browser in pixels. For example, `}<inlineCode parentName="td">{`1536x864`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accessed domain.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of event in the page. One of the following:`}<ul><li>{`Active Elements`}</li><li>{`Abandonment Elements`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Internet provider of the customer. For example, `}<inlineCode parentName="td">{`Vodafone Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain  of the page that contains the link that the visitor used to open the page. For example, `}<inlineCode parentName="td">{`https://www.google.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sequence of terms that the visitor used in the last search. For example, `}<inlineCode parentName="td">{`kitchen knive`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`GENERAL NPS INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/"
              }}>{`Visitors`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood detected and recorded for the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPS value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Net Promoter Score (NPS) value, typically, is a value between 0 and 10, where 0 means that the customer is not happy and will not recommend the business to anyone, and 10 means that the customer is very happy and will recommend the business to everyone.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`LEAD INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/leads/"
                }}>{`Leads`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Closing date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closing date of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`For Leads, creation date of the lead.`}</li><li>{`For Ecommerce, creation date of the cart. `}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`For IVRs, duration of the interaction in the IVR. If the IVR forwards the interaction to an operator, this duration doesn't include time spent with the operator.`}</li><li>{`For Leads, sum of the durations of all the contacts associated with the lead.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of `}<a parentName="td" {...{
              "href": "/glossary/#rgus"
            }}>{`RGUS`}</a>{` that were realized in the lead.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: If the lead had a sale.`}</li><li><strong parentName="td">{`No`}</strong>{`: If the lead did not had a sale.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sale Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the lead resulted in a sale, contains the date when the sale was completed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total associated contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of contacts associated with the lead.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`LOCATION INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/"
                }}>{`ECommerce`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/events/"
                }}>{`Events`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/"
                }}>{`Outbound Campaigns Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/"
                }}>{`Visitors`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`City`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`City from where the visitor used the site.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the customer. For example, `}<inlineCode parentName="td">{`Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the city of the customer. For example, `}<inlineCode parentName="td">{`Lisbon`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`OUTBOUND CAMPAIGN CONTACT INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/"
              }}>{`Outbound Campaigns Actions`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact list that the campaign used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email of the contact. For example, `}<inlineCode parentName="td">{`regular.joe@mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain of the contact email. For example, `}<inlineCode parentName="td">{`mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID in other campaigns.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`OUTBOUND CAMPAIGNS > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><p><a parentName="td" {...{
                "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/"
              }}>{`Outbound Campaigns Actions`}</a></p></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bcc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email blind copy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email copy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, name of external campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`From`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email that appears in the sent email. For example, `}<inlineCode parentName="td">{`do-no-reply@amazing-campaign.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Offsite campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the offsite campaign responsible for the action:`}<ul><li>{`Email Outbound`}</li><li>{`SMS Outbound`}</li><li>{`Voice Outbound`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reply to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email address to where the receiver can reply.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SEND INFO > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-actions/"
                }}>{`Outbound Campaigns Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-email/"
                }}>{`Outbound Campaigns Email`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push-actions/"
                }}>{`Outbound Campaigns Push Actions`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-push/"
                }}>{`Outbound Campaigns Push`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-sms/"
                }}>{`Outbound Campaigns SMS`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/outbound-campaigns-voice/"
                }}>{`Outbound Campaigns Voice`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Context of the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill of the operator that handled the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the email was sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of credits attributed to the operator that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date the contact was added to the campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date the contact was added to the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the SMS was not delivered, contains the message explaining the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the most recent recorded contact of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date in which the campaign updated the status of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tha handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tags associated to the operator that handle the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider of the SMS service. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date in which the SMS was sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending day.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending hour.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent month (MM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending month.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending quarter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending week.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending weekday.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending year.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the contact viewed the email or SMS.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SESSION INFORMATION > Device information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/leads/"
                }}>{`Leads`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser that the using is using. For example, `}<inlineCode parentName="td">{`Chrome Android`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphical resolution of the browser in pixels. For example, `}<inlineCode parentName="td">{`1536x864`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brand family of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Galaxy A12`}</inlineCode>{`, `}<inlineCode parentName="td">{`IPhone`}</inlineCode>{`, or `}<inlineCode parentName="td">{`Macintosh`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`SM-A125f`}</inlineCode>{`, `}<inlineCode parentName="td">{`Iphone 11`}</inlineCode>{`, or `}<inlineCode parentName="td">{`MacBook Pro`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operating system of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Android`}</inlineCode>{`, `}<inlineCode parentName="td">{`Windows`}</inlineCode>{`, or  `}<inlineCode parentName="td">{`IPhone Osx`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of device. One of the following:`}<ul><li>{`Smartphone`}</li><li>{`Tablet`}</li><li>{`Desktop`}</li><li>{`Console`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vendor of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Samsung`}</inlineCode>{`, `}<inlineCode parentName="td">{`Apple`}</inlineCode>{`, or `}<inlineCode parentName="td">{`HP`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SESSION INFORMATION > General`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/broadcast-contacts/"
                }}>{`Broadcast Contacts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts-attempts/"
                }}>{`Contacts Attempts`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/contacts/"
                }}>{`Contacts`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FID (external ID)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`External identifier of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source or link that referred the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain  of the page that contains the link that the visitor used to open the page. For example, `}<inlineCode parentName="td">{`https://www.google.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sequence of terms that the visitor used in the last search. For example, `}<inlineCode parentName="td">{`kitchen knive`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP of the session.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL of the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL that contains the link to the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address that the customer visited to reach the contact center.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`VISITORS INFORMATION > > Without sub-category`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Panels`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/ecommerce/"
                }}>{`ECommerce`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/events/"
                }}>{`Events`}</a></li><li><a parentName="td" {...{
                  "href": "/engagement-cloud/analytics/analytics-panel-reference/visitors/"
                }}>{`Visitors`}</a></li></ul></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Fields`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the cookie of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the first recorded contact of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First visit date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the first recorded access of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the visitor that Digital Experience Platform attributes to the visitor using visitor information like IP address and browser type, among others.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of visitor identification. For example, `}<inlineCode parentName="td">{`2031253224`}</inlineCode>{`.`}<br />{`One of the following:`}<ul><li><strong parentName="td">{`FId`}</strong>{`: The Digital Experience Platform generated the visitor identifier.`}</li><li><strong parentName="td">{`Cookie ID`}</strong>{`: Digital Experience Platform read the visitor ID from a Cookies.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Internet provider of the customer. For example, `}<inlineCode parentName="td">{`Vodafone Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the most recent recorded contact of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last visit date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the last access of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of te following:`}<ul><li>{`No - The visitor didn't use a mobile device to make the visit.`}</li><li>{`Yes - The visitor used a mobile device to make the visit.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source or link that referred the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP address of the visitor. For example, `}<inlineCode parentName="td">{`881.28.312.791`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search expression`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last expression that the visitor wrote in the search of the website. For example, `}<inlineCode parentName="td">{`creating a campaign`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifiers of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`A1B345C6DEFGH789I0JK`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`January Promo`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of contacts associated with the visitor. For example, `}<inlineCode parentName="td">{`4 contacts`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total number of times a visitor accesses the site during a specified period.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the visitor.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      